export default class SystemConstant {
    public static protocol = 'https';
    public static SERVER_WEB = {
        HOST: 'localhost:11180',
    };
    public static TextExtraction = {
        host: "dev.analytics.anybim.vn",
        API: {
            PostResultPreview: 'api/extraction/TitleBlockResultOverView',
            ResultPreviewPage: 'api/extraction/TitleBlockResultEachPage',
            SearchCurrentSheet: (pageNumber: number) => `api/extraction/SearchTBFieldValueCurrSheet/${pageNumber}`,
            applyField: (pageIndex: number) => `api/extraction/ApplyTBFieldValueCurrSheet/${pageIndex}`,
            applyAllSheet: (pageIndex: number) => `api/extraction/ApplyTBFieldValueAllSheets/${pageIndex}`,
            getFileInfo: (baseFileId: string) => `api/file/${baseFileId}`,
            downloadReportExcel: (baseFileId: string) => `api/file/DownloadReportExcel/${baseFileId}`,
            downloadContentExcel: (baseFileId: string) => `api/file/downloadContentExcel/${baseFileId}`,
            completeVerification: (baseFileId: string) => `api/extraction/CompleteVerify/${baseFileId}`,
            analytics: 'api/file/Analyse/-1',
            getLinkSheets: (baseFileId: string) => `api/extraction/GetLinkSheets/${baseFileId}`,
            getDisciplineLayout: (baseFileId: string) => `api/file/GetLayouts/${baseFileId}`,
            setDisciplineLayout: (baseFileId: string) => `api/file/SetLayouts/${baseFileId}`,
            getTitleBlockImage: (baseFileId: string, pageIndex: number) => `api/extraction/GetTitlBlockImage/${baseFileId}/${pageIndex}`,
            getTBGraphicInfor: (baseFileId: string, pageIndex: number) => `api/extraction/GetTBGraphicInfor/${baseFileId}/${pageIndex}`,
            getTemplateDisciplines: 'api/file/GetTemplateDisciplines',
            getTemplateSheetTypes: 'api/file/GetTemplateSheetTypes',
            updateDisciplineType: (baseFileId: string, pageIndex: number, updateValue: string) => `api/extraction/UpdateDisciplineType/${baseFileId}/${pageIndex}/${updateValue}`,
            updateSheetType: (baseFileId: string, pageIndex: number, updateValue: string) => `api/extraction/UpdateSheetType/${baseFileId}/${pageIndex}/${updateValue}`,
        }
    };
    public static Stream = {
        host: "sit.3dviewer.anybim.vn/server/",
        API: {
            DownloadFile: 'api/download'
        }
    };
    public static Conversion = {
        host: "sit.3dviewer.anybim.vn/service/conversion",
        API: {
            CACHE: {
                GetFile: 'api/StreamFile/fileid'
            }
        }
    };

    public static File = {
        host: "sit.3dviewer.anybim.vn/server/file",
    }

}
