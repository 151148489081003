import { useEffect, useState } from "react";
import { pdfInitAddOns, pdfInitTemplate } from "helper/pdf.helper";
import { MapPdfType, StatePdfCreate } from "common/define";
import { DefaultAnnotAttribute } from "common/define-foxit";
import { themeValue } from "theme/Theme";

interface LicenseFoxit {
    licenseKey: string;
    licenseSN: string;
}

const licenseFoxit: LicenseFoxit = {
    licenseKey: 'PjzsmLRROSisb/teo9fUwn1s2hMzHWzs460Xg2zLWxa3weSArbMK+euIQNiA9T2lSJVh5m5LJqjVpHW+L/T0hEcRSLF3UP51HRoPgG7GtWvPV6/JDeEWxSa+bDKC33nqLxUTSS5gJcwCapUiPMm+1c2m3EYbOREuzpvc0nnCf/kMvQ/YPAGyilZ4+I61nsVTFfJGTF/+eulf7jAdt6drWvLLHcY+km7JGPIeBRLIptAo1LjZ53TCBQ/GApu6bmnpIh+kLbVfLM4l3Ge3C9GAEqBYhNX2Vp5qQqoAvkYscamxJ/S+s/sAcYqtG/sukPZsTksHafJ+xrW44LZaJN9rA+igopVhNuA4/VdeXRbm67GTJY5PlecH2rt7FN6JFxtD6Y6gOXrbmFSQs6O4sdv7YDGxRPEyUWIbQ+tjrR9Yt01IQ/Ulj1ZQkyrhoZMD75SfAqNTBu5GVuKrmN1owFtElIcsp5Dx4RFek86735xQYhg5dXojSOPIsK0wbIDpaZZztUcqbaIlnlDHOkMTZMTbfniyjGUOx6QPMCldZ7G/21h732VlRSYSrq0xzI9vfRDMj/hobznIZrEtamdBMaVG1vDSEXPMaI47k/GTHiM7Z8mM5zKXqkSqpjXF98vzOn81hTmjw4tDsOb7eT6lAWd6kPtGoRq0g9lrqpCV+Llj949dKOHfQotK4x/CAkyw7Xi0Df1vl79kpeH1pewEdqj25w3lK8Z5pV9foFSnNgtPc3w8dqXl3XValIACpd4HQCyGIMuytNpdYl2PNbiRxa5EYImxNU4EQe8SVDeNf0n1tglPKAu0oJFUEOu0QZM+vMK5Ecm08lscpmoNS3JKMN0kM08+eofvgkt4bEjSIu/3Emjnge4Z1Hs3SztsQZXWcFcY9IPzt4frj9DTXoVR2hZF/gTqimSUDgq+xajagedl5yNtcxE0qgx3kISN3SBOKfMN7g054pEtHctR+18KQpHqujWNcx9FbAw+yiNIGuGpKgmqdfRwauwt2gGB19kFPQks5BVOu1YAwWhI8tmmQgUYt+LkBi68O5f6a5ROsdWI+pY+Blk0gnLrqr4Y31ZTfo3dVlrBRPTtENZxoS8elPV8J/X0QStqfMWDJWLD7hAP93Kl+bJX8P8X62zNIJZUzDASHKX3Pcg/1XVASO8SkqHgRFHJVpI/6+NnEiMJRCAhi09VHo9axI5a7M4uPrxNdxeeORfCdNPM+aYLY508mgTDwostnsOgppbtxtQU+bVSy7yic1gHKFKvUf6UGq0dduXEqfXVYk2/IaFdb7RWyBM=',
    licenseSN: 'eZwRWUT4rUQo5UH0Gdf3tHh3Zgcq1zr/w+8Q3Xf45Oy0AsK49ZMTNw=='
}

const pdfViewerCurrent = new Map<MapPdfType, any>();
export function getPdfViewer(type: MapPdfType = 'pdfViewer') {
    return pdfViewerCurrent.get(type);
}

function setDefaultConfigAnnot(pdfUI: any) {
    const config: DefaultAnnotAttribute = {
        color: Number(themeValue.palette.primary.replace('#', '0xff')),
        borderInfo: {
            width: 2,
        }
    }
    pdfUI.setDefaultAnnotConfig((type: any) => {
        return config
    })
}

export default function useCreatePdfViewer(renderElement: React.RefObject<HTMLDivElement>): StatePdfCreate {
    const [pdfCreated, setPdfCreated] = useState<StatePdfCreate>({
        pdfUI: null,
        pdfViewer: null
    });

    async function setPdfViewer(pdfUIResult: any) {
        const pdfViewer = await pdfUIResult.getPDFViewer();
        setPdfCreated({
            pdfUI: pdfUIResult,
            pdfViewer: pdfViewer
        });
        pdfViewerCurrent.set('pdfUI', pdfUIResult);
        pdfViewerCurrent.set('pdfViewer', pdfViewer);
        setDefaultConfigAnnot(pdfUIResult);
    }


    useEffect(() => {
        if (renderElement.current) {
            const pdfUIResult = new UIExtension.PDFUI({
                viewerOptions: {
                    libPath: `${process.env.PUBLIC_URL}/foxit-core`,
                    defaultScale: 'fitHeight',
                    defaultViewMode: 'single-page-view-mode',
                    jr: {
                        ...licenseFoxit,
                        workerPath: `${process.env.PUBLIC_URL}/foxit-core/`,
                        enginePath: `${process.env.PUBLIC_URL}/foxit-core/jr-engine/gsdk`,
                    }
                },
                renderTo: renderElement.current,
                template: pdfInitTemplate.join(''),
                addons: pdfInitAddOns,
            });
            setPdfViewer(pdfUIResult);
        }
    }, [renderElement]);

    return pdfCreated;
}