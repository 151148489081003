/* eslint-disable react-hooks/exhaustive-deps */
import { BoundaryScan, LinkSheetData, LinkSheetItem, LocationScan, StatePdfCreate } from "common/define";
import { FoxitEvents } from "common/define-foxit";
import PdfHelper from "helper/pdf.helper";
import { useEffect } from "react";
import { AnyAction } from "redux";
import { fetchScanField, setIsScaning } from "redux/detailPage.slice";
import { globalStatePdfViewer, setStateWhenRenderSuccess, setRenderDone } from "redux/pdfViewer.slice";
import { useDispatchRoot, useSelectorRoot } from "redux/store";
import { jumpPage, setHighlightSheetLoading, setPdfViewerLoading } from "redux/actionInfo.slice";
import { link } from "fs";
import PdfViewer from "container/viewer/PdfViewer";
type HandleEventObject = [FoxitEvents, Function[] | Function];
export enum PDFAnnotFlag {
    Invisible = 1,
    Hidden = 2,
    Print = 4,
    NoZoom = 8,
    NoRotate = 16,
    NoView = 32,
    ReadOnly = 64,
    Locked = 128,
    ToggleNoView = 256,
    LockedContents = 512
}
function registerHandleEvent(pdfViewer: any, mapEvent: HandleEventObject[]) {
    if (pdfViewer) {
        mapEvent.forEach(arr => {
            const [nameEvent, arrHandle] = arr;
            if (Array.isArray(arrHandle)) {
                arrHandle.forEach(event => {
                    pdfViewer.getEventEmitter().on(nameEvent, event);
                })
            } else {
                pdfViewer.getEventEmitter().on(nameEvent, arrHandle);
            }
        })
    }
}
function handleRenderFileSuccess(pdfViewer: any, dispatch: (event: AnyAction) => void, pageCurrentIndex: number, linkSheetsData: LinkSheetData[]) {
    const currentScale = pdfViewer.getPDFPageRender(0).scale;
    dispatch(setStateWhenRenderSuccess(currentScale));
    dispatch(setRenderDone(true));
    console.log('render success');
    loadAddHighlightByPage(pageCurrentIndex, linkSheetsData, dispatch);
}
async function loadAddHighlightByPage(pageCurrentIndex: number, linkSheetsData: LinkSheetData[], dispatch: (event: AnyAction) => void, collisionRect?: LocationScan) {
    dispatch(setHighlightSheetLoading(false));
    await PdfHelper.highlightLinkSheetCurrentPage(pageCurrentIndex, linkSheetsData, collisionRect).then(val => {
        dispatch(setHighlightSheetLoading(true));
    });
}
function handleZoomToSuccess(pdfViewer: any) {
    const currentScale = pdfViewer.getPDFPageRender(0).scale;
    globalStatePdfViewer.currentPdfScale = currentScale;
}

function wheelRequest(wheelEvent: React.WheelEvent, pdfViewer: any) {
    const currentScale = globalStatePdfViewer.currentPdfScale;
    const { clientX: x, clientY: y } = wheelEvent;
    if (wheelEvent.deltaY >= 1) {
        PdfHelper.zoomInOut(pdfViewer, currentScale, 'out', { x, y })
    } else if (wheelEvent.deltaY <= -1) {
        PdfHelper.zoomInOut(pdfViewer, currentScale, 'in', { x, y })
    }
}

function addAnnotSuccess(annot: any, dispatch: (event: AnyAction) => void) {
    dispatch(setIsScaning(true));

    // annot unable to select
    // annot.setFlags(PDFAnnotFlag.ReadOnly, null);
    mapAnnot.set('annot', annot);
    setTimeout(async () => {
        const boundaryScan: BoundaryScan = annot.info.rect;
        const { left, top, bottom, right } = boundaryScan;
        const locationConvert: LocationScan = {
            width: Math.abs(right - left),
            height: Math.abs(top - bottom),
            x: left,
            y: bottom
        };

        dispatch(fetchScanField(locationConvert));
    }, 0);
}
function unActiveAnnotPDF(annotRender: any, pdfViewer: any) {
    const { annotsRender } = pdfViewer.getPDFPageRender(annotRender.annot.page.info.index);
    annotsRender.unActiveAnnot();
}
async function highlightDestWithZoom(destRect: LocationScan, pageIndex: number, dispatch: (event: AnyAction) => void) {
    const highlightAnnot: any[] = await PdfHelper.highlightDestination(destRect, pageIndex); // highlight the destination item in red
    highlightAnnot.forEach(val => val.setFlags(PDFAnnotFlag.ReadOnly, null))
    const pdfDocRender = pdfData.pdfViewer.getPDFDocRender();
    await PdfHelper.zoomExcute(pdfData.pdfViewer, 1);
    const position = PdfHelper.getAnnotPosByRotation(highlightAnnot[0]);
    setTimeout(function () {
        pdfDocRender.goToPage(pageIndex, position, true).then((val: any) => dispatch(setPdfViewerLoading(false)));
    }, 500)
}
async function activateAnnot(annotRender: any, pdfViewer: any, dispatch: (event: AnyAction) => void, linkSheetsData: LinkSheetData[]) {
    if (annotRender.annot.info.title === "destination") {
        console.log('destination highlighted');
    } else {
        dispatch(setPdfViewerLoading(true));
        switch (annotRender.annot.info.type) {
            case "highlight":
                const destinationHighlight: LinkSheetItem = JSON.parse(annotRender.annot.info.title);
                dispatch(jumpPage(destinationHighlight.pageIndex));
                await PdfHelper.removeAllSingleHighlight().then(val => {
                    // highlight all link in page exclude the destination item
                    loadAddHighlightByPage(destinationHighlight.pageIndex, linkSheetsData, dispatch, destinationHighlight.rect).then(val => {
                        highlightDestWithZoom(destinationHighlight.rect, destinationHighlight.pageIndex, dispatch)
                    });
                })
                break;

            case "link":
                const currentSourcePage = annotRender.annot.page.info.index;
                const linkRect: BoundaryScan = annotRender.annot.info.rect;
                const highlightCollide = PdfHelper.checkHighLightCollideLink(currentSourcePage, linkRect, linkSheetsData);
                const destinationLink = annotRender.annot.info.action.destination;
                dispatch(jumpPage(destinationLink.pageIndex));
                await PdfHelper.removeAllSingleHighlight().then(val => {
                    if (highlightCollide) {
                        // highlight all link in page exclude the destination item
                        loadAddHighlightByPage(destinationLink.pageIndex, linkSheetsData, dispatch, highlightCollide.destinationItem.rect).then(val => {
                            highlightDestWithZoom(highlightCollide.destinationItem.rect, highlightCollide.destinationItem.pageIndex, dispatch);
                        });
                    } else {
                        // highlight all link in page exclude the destination item
                        loadAddHighlightByPage(destinationLink.pageIndex, linkSheetsData, dispatch, undefined).then(val => {
                            dispatch(setPdfViewerLoading(false));
                        });
                    }
                });
                break;
        }
    }
    unActiveAnnotPDF(annotRender, pdfViewer);
}

export async function getCurrentPdfPage(pageIndex: number) {
    if (pdfData && pdfData.pdfViewer) {
        const pdfPage = await PdfHelper.getPdfPage(pdfData.pdfViewer, pageIndex);
        return pdfPage;
    }
    return new Promise((_resolve, _reject) => { });
}
export const mapAnnot = new Map<'annot', any>();
let pdfData: any = null;
export default function useHandleEventPdf(pdfCreated: StatePdfCreate) {
    pdfData = pdfCreated;
    const dispatch = useDispatchRoot();
    const { pageCurrentIndex, linkSheetsData } = useSelectorRoot(state => state.actionInfo);

    const handleEvent: HandleEventObject[] = [
        ['render-file-success', () => handleRenderFileSuccess(pdfCreated.pdfViewer, dispatch, pageCurrentIndex, linkSheetsData)],
        ['zoom-to-success', () => handleZoomToSuccess(pdfCreated.pdfViewer)],
        ['wheel-request', (wheelEvent: React.WheelEvent) => wheelRequest(wheelEvent, pdfCreated.pdfViewer)],
        ['add-annot-success', (annot: any) => addAnnotSuccess(annot, dispatch)],
        // ['active-annotation', (annotRender: any) => activateAnnot(annotRender, pdfCreated.pdfViewer, dispatch, linkSheetsData)],
        ['tap-annotation', (annotRender: any) => activateAnnot(annotRender, pdfCreated.pdfViewer, dispatch, linkSheetsData)],
    ];

    useEffect(() => {
        if (pdfCreated.pdfViewer) {
            registerHandleEvent(pdfCreated.pdfViewer, handleEvent);
        }
    }, [pdfCreated.pdfViewer])
}