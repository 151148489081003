import { BaseFileInfo, CompleteVerifyData, DataApplySheet, DetailInfoPage, DisciplineLayout, DisciplineLayoutItem, LinkSheetData, LocationScan, PageReviewShortcut, ResponseFileInfo, TBGraphicInfo } from "common/define";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import Http from "./http.api";
import SystemConstant from "./SystemConstant";

interface DataRequestScan {
    location: LocationScan,
    baseFileId: string
}

export default class TextExtractionApi {
    private static host = `${SystemConstant.protocol}://${SystemConstant.TextExtraction.host}`;
    static getOverViewFile(baseFileId: string): Observable<PageReviewShortcut[]> {
        const api = SystemConstant.TextExtraction.API.PostResultPreview;
        const res = Http.get(`${TextExtractionApi.host}/${api}/${baseFileId}`).pipe(map((re: any) => re as PageReviewShortcut[]));
        return res;
    }
    static getDetailInfoPage(baseFileId: string | undefined, pageIndex: number): Observable<DetailInfoPage[]> {
        const api = SystemConstant.TextExtraction.API.ResultPreviewPage;
        return Http.get(`${TextExtractionApi.host}/${api}/${baseFileId}/${pageIndex}`).pipe(map((re: any) => re as DetailInfoPage[]));
    }
    static scanFieldValue(baseFileId: string, pageIndex: number, location: LocationScan): Observable<string> {
        const api = SystemConstant.TextExtraction.API.SearchCurrentSheet(pageIndex);
        const dataRequest: DataRequestScan = {
            location,
            baseFileId
        };
        return Http.post(`${TextExtractionApi.host}/${api}`, dataRequest).pipe(map(re => re as string));
    }
    static applyFieldCurrentSheet(pageIndex: number, data: DataApplySheet): Observable<string> {
        const api = SystemConstant.TextExtraction.API.applyField(pageIndex);
        return Http.post(`${TextExtractionApi.host}/${api}`, data).pipe(map(re => re as string));
    }
    static applyFieldAllSheet(pageIndex: number, data: DataApplySheet): Observable<string> {
        const api = SystemConstant.TextExtraction.API.applyAllSheet(pageIndex);
        return Http.post(`${TextExtractionApi.host}/${api}`, data).pipe(map(re => re as string));
    }
    static getFileInfo(baseFileId: string) {
        const api = SystemConstant.TextExtraction.API.getFileInfo(baseFileId);
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.get(uri).pipe(map(re => re as ResponseFileInfo));
    }
    static downloadReportFileExcel(baseFileId: string) {
        const api = SystemConstant.TextExtraction.API.downloadReportExcel(baseFileId);
        const uri = `${TextExtractionApi.host}/${api}`;
        return uri;
    }
    static downloadContentFileExcel(baseFileId: string) {
        const api = SystemConstant.TextExtraction.API.downloadContentExcel(baseFileId);
        const uri = `${TextExtractionApi.host}/${api}`;
        return uri;
    }
    static completeVerification(baseFileId: string, data: CompleteVerifyData) {
        const api = SystemConstant.TextExtraction.API.completeVerification(baseFileId);
        const uri = `${TextExtractionApi.host}/${api}`;
        const res = Http.post(uri, data);
        return res;
    }
    static getAllLinkSheets(baseFileId: string) {
        const api = SystemConstant.TextExtraction.API.getLinkSheets(baseFileId);
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.get(uri).pipe(map(re => re as LinkSheetData[]));
    }
    static analytic(data: BaseFileInfo) {
        const api = SystemConstant.TextExtraction.API.analytics;
        return Http.post(`${TextExtractionApi.host}/${api}`, data).pipe(map(re => re as string))
    }
    static getDisciplineLayout(baseFileId: string) {
        const api = SystemConstant.TextExtraction.API.getDisciplineLayout(baseFileId);
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.get(uri).pipe(map(re => re as DisciplineLayout));
    }
    static setDisciplineLayout(data: DisciplineLayoutItem[], baseFileId: string) {
        const api = SystemConstant.TextExtraction.API.setDisciplineLayout(baseFileId);
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.post(`${TextExtractionApi.host}/${api}`, data).pipe(map(re => re as string))
    }

    static fetchTitleBlockImage(baseFileId: string, pageIndex: number) {
        const api = SystemConstant.TextExtraction.API.getTitleBlockImage(baseFileId, pageIndex);
        const uri = `${TextExtractionApi.host}/${api}`;
        // return Http.get(uri).pipe(map(re => re as any));
        return uri;
    }

    static getTBGraphicInfo(baseFileId: string, pageIndex: number) {
        const api = SystemConstant.TextExtraction.API.getTBGraphicInfor(baseFileId, pageIndex);
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.get(uri).pipe(map(re => re as TBGraphicInfo));
    }

    static getTemplateDisciplines() {
        const api = SystemConstant.TextExtraction.API.getTemplateDisciplines;
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.get(uri).pipe(map(re => re as string[]));
    }

    static getTemplateSheetTypes() {
        const api = SystemConstant.TextExtraction.API.getTemplateSheetTypes;
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.get(uri).pipe(map(re => re as string[]));
    }

    static updateDisciplineType(baseFileId: string, pageIndex: number, updateValue: string) {
        const api = SystemConstant.TextExtraction.API.updateDisciplineType(baseFileId, pageIndex, updateValue);
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.get(uri);
    }

    static updateSheetType(baseFileId: string, pageIndex: number, updateValue: string) {
        const api = SystemConstant.TextExtraction.API.updateSheetType(baseFileId, pageIndex, updateValue);
        const uri = `${TextExtractionApi.host}/${api}`;
        return Http.get(uri);
    }
}
