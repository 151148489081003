import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import StreamApi from "../api/Stream.api";
import ConversionAPI from "../api/Conversion.api";
import { BaseFileInfo, TypeOperator } from "common/define";
import FileApi from "api/File.api";

export type CurrentOperator = Extract<TypeOperator, 'pan' | 'zoomWindow' | 'select'>;
interface PdfViewerState {
    loading: boolean;
    renderSuccess: boolean;
    currentOperator: CurrentOperator;
    renderDone: boolean
}

const initState: PdfViewerState = {
    loading: false,
    renderSuccess: false,
    currentOperator: 'pan',
    renderDone: false,
};

export const globalStatePdfViewer = {
    currentPdfScale: 0
};
export const fetchFile = createAsyncThunk(
    'pdfViewer/fetchFile',
    async ({ baseFileInfo, pdfUI }: { baseFileInfo: BaseFileInfo | null, pdfUI: any }, { dispatch }) => {
        try {
            dispatch(setLoadingPdfViewer(true));
            if (baseFileInfo) {
                const stream = await ConversionAPI.getFileInfo(baseFileInfo).toPromise();
                if (process.env.REACT_APP_IS_STREAM_PDF === 'false') {
                    const response = await fetch(StreamApi.getUrlFile(stream));
                    if (response.ok) {
                        const buffer = await response.arrayBuffer();
                        pdfUI.openPDFByFile(buffer);
                    }
                }
                else {
                    const url = FileApi.getUrlStreamfile(stream);
                    pdfUI.openPDFByHttpRangeRequest({
                        range: {
                            url,
                        },
                    });
                }
            }

        } catch (error) {
            dispatch(setLoadingPdfViewer(false));
        }
    }
);

const pdfViewerSlice = createSlice({
    name: 'pdfViewer',
    initialState: initState,
    reducers: {
        setLoadingPdfViewer(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        setRenderSuccess(state, action: PayloadAction<boolean>) {
            state.renderSuccess = action.payload;
        },
        setStateWhenRenderSuccess(state, action: PayloadAction<number>) {
            globalStatePdfViewer.currentPdfScale = action.payload;
            state.renderSuccess = true;
            state.loading = false;
        },
        setCurrentOperator(state, action: PayloadAction<CurrentOperator>) {
            state.currentOperator = action.payload;
        },
        clearViewer(state) {
            state.renderSuccess = false;
        },
        setRenderDone(state, action: PayloadAction<boolean>) {
            state.renderDone = action.payload;
        },
    }
});

export const {
    setLoadingPdfViewer,
    setRenderSuccess,
    setStateWhenRenderSuccess,
    setCurrentOperator,
    clearViewer,
    setRenderDone,
} = pdfViewerSlice.actions;
export default pdfViewerSlice.reducer;
