import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import TextExtractionApi from "api/textExtraction.api";
import { DataApplySheet, LocationScan, TypeApply, ValueOfFieldDetailPage, DetailInfoPage, TBGraphicInfo } from "common/define"
import Utils from "common/utils";
import PdfHelper from "helper/pdf.helper";
import { catchError, concatMap, filter, switchMap, withLatestFrom } from "rxjs/operators";
import { setPageCurrentData } from "./actionInfo.slice";
import { escapeAll, setLoadingApp } from "./app.slice";
import { RootEpic } from "./rootReducers";
import _ from "lodash";
import { merge } from "rxjs";
interface ActionApply {
    value: string;
    type: TypeApply;
}
interface DetailPageState {
    visibleConfirmDialog: boolean;
    visibleAddFieldDialog: boolean;
    fieldFocus: null;
    fieldScanMatched: string | null;
    boundaryScan: LocationScan | null;
    isScaning: boolean;
    resultScan: string;
    additionalFieldSelected: DetailInfoPage[];
    fieldKey: string;
    TBresult: any;
    TBgraphicInfo: TBGraphicInfo | null;
    TBSheetTypes: string[];
    TBDisciplineTypes: string[];
    isUpdatingTBType: boolean;
    TBpageSelected: number | null;
}

const initState: DetailPageState = {
    visibleConfirmDialog: false,
    visibleAddFieldDialog: false,
    fieldFocus: null,
    fieldScanMatched: null,
    boundaryScan: null,
    isScaning: false,
    resultScan: '',
    additionalFieldSelected: [],
    fieldKey: '',
    TBresult: null,
    TBgraphicInfo: null,
    TBSheetTypes: [],
    TBDisciplineTypes: [],
    isUpdatingTBType: false,
    TBpageSelected: null,
}

const detailPageSlice = createSlice({
    name: 'detailPage',
    initialState: initState,
    reducers: {
        fetchScanField(state, action: PayloadAction<LocationScan>) {
            state.visibleConfirmDialog = true;
            state.boundaryScan = action.payload;
            state.resultScan = ''
        },
        setVisibleConfirmDialog(state, action: PayloadAction<boolean>) {
            state.visibleConfirmDialog = action.payload
        },
        setVisibleAddFieldDialog(state, action: PayloadAction<boolean>) {
            state.visibleAddFieldDialog = action.payload
        },
        setfieldScanMatched(state, action: PayloadAction<string>) {
            state.fieldScanMatched = action.payload
        },
        setIsScaning(state, action: PayloadAction<boolean>) {
            state.isScaning = action.payload;
        },
        updateResultScan(state, action: PayloadAction<string>) {
            state.resultScan = action.payload;
        },
        apply(state, action: PayloadAction<ActionApply>) { },
        escapeDetail(state) {
            state.fieldFocus = null;
            state.fieldScanMatched = null;
            state.isScaning = false;
        },
        error(state) {
            state.resultScan = '';
            state.isScaning = false;
            state.fieldScanMatched = null;
            state.boundaryScan = null;
            state.visibleConfirmDialog = false;
            PdfHelper.removeCurrentAnnot();
        },
        setAdditionalField(state, action: PayloadAction<DetailInfoPage[]>) {
            state.additionalFieldSelected = action.payload;
        },
        setUpdateFieldKeyInput(state, action: PayloadAction<string>) {
            state.fieldKey = action.payload;
        },
        setUpdateFieldDataInput(state, action: PayloadAction<string>) {
            const fieldsCopy = _.cloneDeep(state.additionalFieldSelected);
            const newFound = fieldsCopy.find(item => item.fieldName === state.fieldKey);
            if (newFound && newFound.analysisvalue !== null) {
                newFound.analysisvalue.value = action.payload;
                state.additionalFieldSelected = fieldsCopy;
            } else if (newFound && newFound.analysisvalue === null) {
                const newPayloadFromInput: ValueOfFieldDetailPage = {
                    value: action.payload,
                    boundary: {
                        width: 0,
                        height: 0,
                        x: 0,
                        y: 0
                    },
                    priority: 1,
                }
                newFound.analysisvalue = newPayloadFromInput;
                state.additionalFieldSelected = fieldsCopy;
            }
        },
        fetchTbReview(state, action: PayloadAction<number | null>) { return },
        setTBpageSelected(state, action: PayloadAction<number | null>) {
            state.TBpageSelected = action.payload
        },
        setTbResult(state, action: PayloadAction<any>) {
            state.TBresult = action.payload;
        },
        getTBgraphicInfo(state, action: PayloadAction<number>) { return },
        setTBgraphicInfo(state, action: PayloadAction<TBGraphicInfo | null>) {
            state.TBgraphicInfo = action.payload;
        },
        setTBSheetTypes(state, action: PayloadAction<string[]>) {
            state.TBSheetTypes = action.payload;
        },
        setTBDisciplineTypes(state, action: PayloadAction<string[]>) {
            state.TBDisciplineTypes = action.payload;
        },
        updateTBDisciplineType(state, action: PayloadAction<string>) { return },
        updateTBSheetType(state, action: PayloadAction<string>) { return },
        setIsUpdatingTBType(state, action: PayloadAction<boolean>) {
            state.isUpdatingTBType = action.payload;
        },
    }
})

export const fetchScanField$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchScanField.match),
    withLatestFrom(state$),
    switchMap(([creator, state]) => {
        const locationScan = creator.payload;
        const baseFileId = state.app.baseFileInfo?.baseFileId;
        const pageCurrentIndex = state.actionInfo.pageCurrentIndex;
        if (baseFileId && pageCurrentIndex >= 0) {
            return TextExtractionApi.scanFieldValue(baseFileId, pageCurrentIndex, locationScan).pipe(
                concatMap(re => [detailPageSlice.actions.updateResultScan(re), setIsScaning(false)]),
                catchError(err => [
                    detailPageSlice.actions.error(),
                    escapeAll(),
                ])
            )
        }
        return [detailPageSlice.actions.error()];
    })
);

export const apply$: RootEpic = (action$, state$) => action$.pipe(
    filter(apply.match),
    withLatestFrom(state$),
    switchMap(([creator, state]) => {
        const { value, type } = creator.payload;
        const { boundaryScan, fieldScanMatched, additionalFieldSelected } = state.detailPage;
        const { baseFileInfo } = state.app;
        const { pageCurrentIndex, pageCurrentData } = state.actionInfo;
        const bodyResquest: DataApplySheet = {
            location: boundaryScan!,
            baseFileId: baseFileInfo!.baseFileId,
            fieldName: fieldScanMatched!,
            updateValue: value
        };
        const api$ = type === 'applySheet' ?
            TextExtractionApi.applyFieldCurrentSheet(pageCurrentIndex, bodyResquest) :
            TextExtractionApi.applyFieldAllSheet(pageCurrentIndex, bodyResquest);
        return api$.pipe(
            concatMap(re => {
                const newFieldValue: DetailInfoPage = {
                    fieldName: fieldScanMatched!,
                    analysisvalue: {
                        value: value,
                        boundary: boundaryScan!,
                        priority: 1
                    } as ValueOfFieldDetailPage,
                };
                const pageNewData = (additionalFieldSelected as any[]).filter(val => val.fieldName !== fieldScanMatched!);
                const newPageData = [...pageNewData, ...[newFieldValue]];
                return [
                    setPageCurrentData(newPageData as any),
                    escapeAll(),
                    setLoadingApp(false),
                ]
            }),
            catchError(err => [
                escapeAll(),
                detailPageSlice.actions.error()
            ])
        )
    })
)

export const fetchTBreview$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchTbReview.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const currTBpage = action.payload;
        const baseFileId = state.app.baseFileInfo?.baseFileId;
        if (baseFileId && currTBpage !== null) {
            return [
                setTbResult(TextExtractionApi.fetchTitleBlockImage(baseFileId, currTBpage)),
                setTBpageSelected(currTBpage)
            ]
        }
        return [detailPageSlice.actions.error()];

    }))

export const fetchTBgraphicInfo$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchTbReview.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const currTBpage = action.payload;
        const baseFileId = state.app.baseFileInfo?.baseFileId;
        if (baseFileId && currTBpage !== null) {
            return TextExtractionApi.getTBGraphicInfo(baseFileId, currTBpage).pipe(
                concatMap((result: TBGraphicInfo) => [
                    setTBgraphicInfo(result)
                ])
            )
        }
        return [detailPageSlice.actions.error()];
    }))

export const fetchDisciplineType$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchTbReview.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        return TextExtractionApi.getTemplateDisciplines().pipe(
            concatMap((result: string[]) => [
                setTBDisciplineTypes(result)
            ])
        )
    }))

export const fetchSheetType$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchTbReview.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        return TextExtractionApi.getTemplateSheetTypes().pipe(
            concatMap((result: string[]) => [
                setTBSheetTypes(result)
            ])
        )
    }))

export const updateTBDiscipline$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateTBDisciplineType.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {

        const updateValue = action.payload;
        const currSelected = state.detailPage.TBpageSelected;
        const baseFileId = state.app.baseFileInfo?.baseFileId;
        if (currSelected && baseFileId) {
            setIsUpdatingTBType(true);
            return TextExtractionApi.updateDisciplineType(baseFileId, currSelected, updateValue).pipe(
                concatMap((result) => [
                    setIsUpdatingTBType(false),
                    fetchTbReview(currSelected)
                ])
            )
        }
        return [detailPageSlice.actions.error()];
    }))

export const updateTBSheet$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateTBSheetType.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {

        const updateValue = action.payload;
        const currSelected = state.detailPage.TBpageSelected;
        const baseFileId = state.app.baseFileInfo?.baseFileId;
        if (currSelected && baseFileId) {
            setIsUpdatingTBType(true);
            return TextExtractionApi.updateSheetType(baseFileId, currSelected, updateValue).pipe(
                concatMap((result) => [
                    setIsUpdatingTBType(false),
                    fetchTbReview(currSelected)
                ])
            )
        }
        return [detailPageSlice.actions.error()];
    }))

export const {
    setVisibleConfirmDialog,
    setVisibleAddFieldDialog,
    setfieldScanMatched,
    fetchScanField,
    setIsScaning,
    apply,
    escapeDetail,
    setAdditionalField,
    setUpdateFieldKeyInput,
    setUpdateFieldDataInput,
    fetchTbReview,
    setTbResult,
    getTBgraphicInfo,
    setTBgraphicInfo,
    setTBSheetTypes,
    setTBDisciplineTypes,
    updateTBDisciplineType,
    updateTBSheetType,
    setIsUpdatingTBType,
    setTBpageSelected,

} = detailPageSlice.actions;
export default detailPageSlice.reducer;