import CLoading from "components/CLoading";
import React, { ComponentClass, FunctionComponent, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

interface RouterItem {
    path: string;
    component: ComponentClass | FunctionComponent;
    exact?: boolean;
    isNotPrivate?: boolean;
}

const ListPages = React.lazy(() => import("../page/list-pages"));
const TextExtractionViewerModule = React.lazy(
    () => import("../container/TextExtractionViewer")
);

const RouterArr: RouterItem[] = [
    {
        path: "/",
        component: ListPages,
        exact: true,
    },
    {
        path: "/viewer",
        component: TextExtractionViewerModule,
        exact: true,
    },
    {
        path: "/listpages",
        component: ListPages,
        exact: true,
    },
];

const MainRouter = (): JSX.Element => {
    return (
        <Router>
            <Suspense fallback={<CLoading visible={true} fullScreen={true} />}>
                <Switch>
                    {RouterArr.map(({ path, component, ...rest }) => {
                        return (
                            <Route
                                path={path}
                                component={component}
                                key={path}
                                {...rest}
                            />
                        );
                    })}
                </Switch>
            </Suspense>
        </Router>
    );
};

export default MainRouter;
