import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TextExtractionApi from "api/textExtraction.api";
import { BaseFileInfo, PageReviewShortcut } from "common/define";
import { throwError } from "rxjs";
import { catchError, concatMap, filter, map, mergeMap, retry, switchMap, withLatestFrom } from "rxjs/operators";
import { updateListOverView } from "./actionInfo.slice";
import { RootEpic } from "./rootReducers";

interface ListPagesState {
    // listPages: PageReviewShortcut[];
    listPages: PageReviewShortcut[];
    loading: boolean;
}

const initState: ListPagesState = {
    listPages: [],
    loading: false
}

const listPagesSlice = createSlice({
    name: 'listPages',
    initialState: initState,
    reducers: {
        fetchListPage(state, action: PayloadAction<string>) {
            state.loading = true;
        },
        setListPages(state, action: PayloadAction<PageReviewShortcut[]>) {
            state.listPages = action.payload;
        },
        setLoadingListPages(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        getAnalytic(state, action: PayloadAction<BaseFileInfo>) { },
        error(state, action: PayloadAction<string>) {
            state.loading = false;
        }
    }
})

let didAnalytic = false;
export const fetchListPages$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchListPage.match),
    withLatestFrom(state$),
    concatMap(([{ payload: baseFileId }, state]) => {
        const baseFileInfo = state.app.baseFileInfo;
        return TextExtractionApi.getOverViewFile(baseFileId).pipe(
            mergeMap(result => {
                if (result?.length > 0) {
                    return [
                        setListPages(result),
                        updateListOverView(result),
                        listPagesSlice.actions.setLoadingListPages(false),
                    ]
                }
                return !didAnalytic ? [listPagesSlice.actions.getAnalytic(baseFileInfo!)] : []
            }),
            catchError(err => [listPagesSlice.actions.error('err get over view')]),
        )
    })
)

export const analytic$: RootEpic = action$ => action$.pipe(
    filter(listPagesSlice.actions.getAnalytic.match),
    switchMap(action => {
        const baseFileInfo = action.payload;
        return TextExtractionApi.analytic(baseFileInfo!).pipe(
            map(reAnalytic => {
                didAnalytic = true;
                return fetchListPage(baseFileInfo.baseFileId)
            }),
            catchError(err => [listPagesSlice.actions.error('err get analytic')])
        )
    })
)
export const { fetchListPage, setListPages } = listPagesSlice.actions;
export default listPagesSlice.reducer;