import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TextExtractionApi from "api/textExtraction.api";
import { DetailInfoPage, ModeAction, PageReviewShortcut, BaseFileInfo, LinkSheetData, CompleteVerifyData } from "common/define";
import Utils from "common/utils";
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { escapeAll } from "./app.slice";
import { RootEpic } from "./rootReducers";
import { setAdditionalField } from "redux/detailPage.slice";
interface ActionInfoState {
    loading: boolean;
    listOverView: PageReviewShortcut[];
    modeAction: ModeAction;
    pageCurrentIndex: number;
    pageCurrentData: any | null;
    isPageCurrentMatched: boolean;
    linkSheetsData: LinkSheetData[];
    linkSheetLoading: boolean;
    highlightSheetLoading: boolean;
    pdfViewerLoading: boolean;
}

const initState: ActionInfoState = {
    loading: false,
    listOverView: [],
    modeAction: 'pages',
    pageCurrentIndex: 0,
    pageCurrentData: null,
    isPageCurrentMatched: false,
    linkSheetsData: [],
    linkSheetLoading: false,
    highlightSheetLoading: true,
    pdfViewerLoading: false,
};

const actionInfoSlice = createSlice({
    name: 'actionInfo',
    initialState: initState,
    reducers: {
        fetchOverView(state, action: PayloadAction<BaseFileInfo>) {
            state.loading = true;
        },
        updateListOverView(state, action: PayloadAction<PageReviewShortcut[]>) {
            state.listOverView = action.payload;
            state.loading = false;
        },
        switchMode(state, action: PayloadAction<ModeAction>) {
            state.loading = true;
            state.modeAction = action.payload;
        },
        jumpPage(state, action: PayloadAction<number>) {
            state.pageCurrentIndex = action.payload;
        },
        setPageCurrentIndex(state, action: PayloadAction<number>) {
            state.pageCurrentIndex = action.payload
        },
        setPageCurrentData(state, action: PayloadAction<DetailInfoPage[] | null>) {
            const data = action.payload;
            state.pageCurrentData = data;
            state.loading = false;
            if (data) {
                const match = Utils.checkMatchByPageDetail(data);
                state.isPageCurrentMatched = match;
            } else {
                state.isPageCurrentMatched = false;
            }
        },
        setIsMatchedPageCurrent(state, action: PayloadAction<boolean>) {
            state.isPageCurrentMatched = action.payload
        },
        completeVerification(state, action: PayloadAction<DetailInfoPage[]>) {
            state.loading = true;
        },
        completeVerificationSuccess(state) {
            state.loading = false;
        },
        err(state, action: PayloadAction<string>) {
            state.loading = false;
        },
        setLinkSheetsData(state, action: PayloadAction<LinkSheetData[]>) {
            state.linkSheetsData = action.payload;
        },
        setLinkSheetLoading(state, action: PayloadAction<boolean>) {
            state.linkSheetLoading = action.payload;
        },
        setHighlightSheetLoading(state, action: PayloadAction<boolean>) {
            state.highlightSheetLoading = action.payload;
        },
        setPdfViewerLoading(state, action: PayloadAction<boolean>) {
            state.pdfViewerLoading = action.payload;
        },
    }
});

export const fetchOverView$: RootEpic = action$ => action$.pipe(
    filter(fetchOverView.match),
    switchMap((data) => {
        return TextExtractionApi.getOverViewFile(data.payload.baseFileId).pipe(
            mergeMap(result => [updateListOverView(result), escapeAll()]),
            catchError(err => [actionInfoSlice.actions.err('err fetch overview file')])
        )
    })
);

export const fetchDetailInfoPage$: RootEpic = (action$, state$) => action$.pipe(
    filter(switchMode.match),
    withLatestFrom(state$),
    switchMap(([creator, state]) => {
        const pageNum = state.actionInfo.pageCurrentIndex;
        return TextExtractionApi.getDetailInfoPage(state.app.baseFileInfo?.baseFileId, pageNum).pipe(
            mergeMap(result => [setPageCurrentData(result), escapeAll()]),
            catchError(err => [setPageCurrentData(null), actionInfoSlice.actions.err('err fetch detail page')])
        )
    })
)

export const completeVerification$: RootEpic = (action$, state$) => action$.pipe(
    filter(completeVerification.match),
    withLatestFrom(state$),
    switchMap(([creator, state]) => {
        const data = creator.payload;
        const baseFileId = state.app.baseFileInfo?.baseFileId;
        const pageNum = state.actionInfo.pageCurrentIndex;
        // const mapData = Utils.mapDataCompleteVerify(data);
        const dataPost: CompleteVerifyData = {
            pageAnalysisItems: data,
            pageIndex: pageNum,
        }
        return TextExtractionApi.completeVerification(baseFileId!, dataPost).pipe(
            mergeMap(re => [
                actionInfoSlice.actions.completeVerificationSuccess(),
                setAdditionalField(data),
            ]),
            catchError(err => [actionInfoSlice.actions.err('err action complete verify')])
        )
    })
)

export const {
    fetchOverView,
    updateListOverView,
    switchMode,
    jumpPage,
    setPageCurrentData,
    setPageCurrentIndex,
    setIsMatchedPageCurrent,
    completeVerification,
    setLinkSheetsData,
    setLinkSheetLoading,
    setHighlightSheetLoading,
    setPdfViewerLoading,
} = actionInfoSlice.actions;

export default actionInfoSlice.reducer;
