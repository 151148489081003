import { BoundaryScan, LinkSheetData, LinkSheetItem, LocationScan } from "common/define";
import { FoxitState } from "common/define-foxit";
import Utils from "common/utils";
import PdfViewer from "container/viewer/PdfViewer";
import { promises } from "dns";
import { getPdfViewer } from "hooks/useCreatePdfViewer";
import { mapAnnot, PDFAnnotFlag } from "hooks/useHandleEventPdf";
import { cloneDeep } from "lodash";
import MathPdfHelper from "./mathPdf.helper";
export const pdfInitTemplate = [
    '<webpdf>',
    ' <toolbar>',
    ' <tabs hidden>',
    ' <tab>',
    ' <div class="flex" style="display: flex;">',
    ' <create-textbox-button></create-textbox-button>',
    ' <create-text-highlight-button></create-text-highlight-button>',
    ' <create-callout-button></create-callout-button>',
    ' <create-pencil-button></create-pencil-button>',
    ' <create-attachment-button></create-attachment-button>',
    ' <create-strikeout-button></create-strikeout-button>',
    ' <create-underline-button></create-underline-button>',
    ' <create-squiggly-button></create-squiggly-button>',
    ' <create-replace-button></create-replace-button>',
    ' <create-caret-button></create-caret-button>',
    ' <create-note-button></create-note-button>',
    ' <create-drawings-dropdown></create-drawings-dropdown>',
    ' <create-area-highlight-button></create-area-highlight-button>',
    ' <hand-button></hand-button>',
    ' <marquee-tool-button></marquee-tool-button>',
    ' <selection-button></selection-button>',
    ' </div>',
    ' </tab>',
    ' </tabs>',
    ' </toolbar>',
    ` <tooltip-layer name="fv--text-selection-tooltip" class="fv--ui-text-selection-tooltip">
          <xbutton @controller="text-sel:CopySelectedTextController" icon-class="fv__icon-popup-copy"></xbutton>
      </tooltip-layer>`,
    ' <viewer></viewer>',
    '</webpdf>',
];

export const pdfInitAddOns = [
    `${process.env.PUBLIC_URL}/foxit-core/uix-addons/undo-redo/`,
];

const minMaxDeltaScale = {
    min: 0.1,
    max: 4,
    delta: 0.1
};
export default class PdfHelper {
    static zoomToExc(pdfViewer: any, nextScale: any, location: { x: number, y: number } | null = null) {
        let p = 0; let d = 0; let h = 0;
        const pdfDocRender = pdfViewer.getPDFDocRender();
        const viewRender = pdfDocRender.viewerRender.$ui.parent()[0] as HTMLElement;
        const bounding = viewRender.getBoundingClientRect();
        const { left, top, width, height } = bounding;
        const clientX = location?.x || width / 2 + left;
        const clientY = location?.y || height / 2 + top;
        let w;
        let _;
        let j = pdfDocRender.pagesRender.viewMode.getVisibleIndexes() || [0];
        for (let i = 0; i < j.length; i++) {
            let c = j[i];
            let a = pdfDocRender.getPDFPageRender(c).$ui.get(0).getBoundingClientRect();
            if (clientX <= a.right && clientX >= a.left && clientY >= a.top && clientY <= a.bottom) {
                p = c;
                w = left - clientX;
                d = clientX - a.left;
                if (d < 0) d = 0;
                _ = top - clientY;
                h = clientY - a.top;
                if (h < 0) h = 0;
                break;
            }
        }
        let S = pdfDocRender.getPDFPageRender(p);
        let D = S.page.reverseDevicePoint([d, h], S.scale, S.getRotation());
        let T = S.page.reverseDeviceOffset([w, _], nextScale, S.getRotation());
        const params = {
            pageIndex: p,
            x: D[0] + T[0],
            y: D[1] + T[1],
        };
        pdfViewer.zoomTo(nextScale, params).catch((() => {
        }));
    }
    static zoomInOut(pdfViewer: any, currentScale: number, type: 'in' | 'out', location: { x: number, y: number } | null = null) {
        let scale: number;
        if (type === 'in') {
            const tempScaleIn = currentScale + minMaxDeltaScale.delta;
            scale = tempScaleIn > minMaxDeltaScale.max ? minMaxDeltaScale.max : tempScaleIn;
        } else {
            const tempScaleIn = currentScale - minMaxDeltaScale.delta;
            scale = tempScaleIn < minMaxDeltaScale.min ? minMaxDeltaScale.min : tempScaleIn;
        }
        PdfHelper.zoomToExc(pdfViewer, scale);
    }
    static goToPage(pdfViewer: any, pageIndex: number) {
        pdfViewer.getPDFDocRender().goToPage(pageIndex, { x: 0, y: 0 }, 0, true);
    }
    static navigatorPage(currentPageIndex: number, type: 'pre' | 'next'): number {
        let page = -1;
        if (type === 'pre') {
            page = currentPageIndex - 1;
        } else if (type === 'next') {
            page = currentPageIndex + 1;
        }
        return page;
    }
    static currentPage(pdfViewer: any): number {
        return pdfViewer.getPDFDocRender().getCurrentPageIndex();
    }
    static async getPdfPage(pdfViewer: any, pageIndex: number) {
        const pdfPage = await pdfViewer.getPDFPageRender(pageIndex)?.getPDFPage();
        return pdfPage;
    }
    static async goHomePage(pdfViewer: any) {
        const { offsetWidth: curElementWidth, offsetHeight: curElementHeight } = pdfViewer.$ui[0].parentElement;
        const pageIndex = PdfHelper.currentPage(pdfViewer);
        const page = await PdfHelper.getPdfPage(pdfViewer, PdfHelper.currentPage(pdfViewer));
        const width = page.getWidth();
        const height = page.getHeight();
        if (width / curElementWidth > height / curElementHeight) {
            await pdfViewer.zoomTo('fitWidth')
        } else {
            await pdfViewer.zoomTo('fitHeight')
        }
        pdfViewer.getPDFDocRender().goToPage(pageIndex, { x: 0, y: 0 }, 0, true);
    }
    static pageHandler(pdfViewer: any) {
        const pageRender = pdfViewer.getPDFPageRender(PdfHelper.currentPage(pdfViewer));
        return pageRender?.$handler;
    }
    static drawMode(mode: FoxitState, pdfViewer: any = null) {
        const pdfViewerFinal = pdfViewer ?? getPdfViewer();
        const stateManager = pdfViewerFinal.getStateHandlerManager();
        stateManager && stateManager.switchTo(mode);
    }
    static async removeAnnot(annot: any) {
        const pdfViewer = getPdfViewer();
        if (pdfViewer) {
            const pageIndex = pdfViewer.getPDFDocRender().getCurrentPageIndex();
            const pdfPage = await pdfViewer.getPDFPageRender(pageIndex)?.getPDFPage();
            pdfPage.removeAnnotById(annot.id)
        }
    }
    static removeCurrentAnnot() {
        const annot = mapAnnot.get('annot');
        if (annot) {
            PdfHelper.removeAnnot(annot);
        }
    }
    static async removeAllSingleHighlight() {
        const pdfViewer = getPdfViewer();
        if (pdfViewer) {
            const pageIndex = pdfViewer.getPDFDocRender().getCurrentPageIndex();
            const removePromise = [];
            const pdfPage = await pdfViewer.getPDFPageRender(pageIndex)?.getPDFPage().then((res: any) => {
                if (res) {
                    res.annots.forEach((val: any) => {
                        if (val.info.type === 'highlight') {
                            try {
                                res.removeAnnotById(val.info.id);
                            }
                            catch (err) { console.log(err) }
                        }
                    })
                }
            });
        }
    }
    static async highlightDestination(sourceItem: LocationScan, page: number) {
        const newCoor: BoundaryScan = {
            left: sourceItem.x,
            bottom: sourceItem.y,
            top: sourceItem.y + sourceItem.height,
            right: sourceItem.x + sourceItem.width,
        }
        const newRect = newCoor;
        const rectA = newRect.left;
        const rectB = newRect.bottom;
        const rectC = newRect.right;
        const rectD = newRect.top;

        const highlightId = Utils.newGuid();
        const highlight = {
            "type": "highlight",
            "color": "#ff4d4d", //red
            "flags": "readOnly",
            "date": "",
            "name": `${highlightId}`,
            "page": page,
            "rect": `${rectA},${rectB},${rectC},${rectD}`,
            "contents": "",
            "width": 2,
            "customEntries": {},
            "style": "solid",
            "opacity": 1,
            "creationdate": "",
            "subject": "",
            "title": "destination",
            "coords": `${rectA},${rectD},${rectC},${rectD},${rectA},${rectB},${rectC},${rectB}`,
        }
        const pdfViewer = getPdfViewer();
        if (pdfViewer) {
            const pdfPage = await pdfViewer.getPDFPageRender(page)?.getPDFPage();
            return await pdfPage.addAnnot(highlight);
        }
        return Promise.resolve();
    }
    static checkRectsCollision(rect1: LocationScan, rect2: LocationScan) { // true = detected
        return rect1.x < rect2.x + rect2.width &&
            rect1.x + rect1.width > rect2.x &&
            rect1.y < rect2.y + rect2.height &&
            rect1.y + rect1.height > rect2.y
    }
    static getHighlightObject(sourceItem: LocationScan, destinationItemText: string, page: number) {
        const newCoor: BoundaryScan = {
            left: sourceItem.x,
            bottom: sourceItem.y,
            top: sourceItem.y + sourceItem.height,
            right: sourceItem.x + sourceItem.width,
        }
        const newRect = newCoor;
        const rectA = newRect.left;
        const rectB = newRect.bottom;
        const rectC = newRect.right;
        const rectD = newRect.top;

        const highlightId = Utils.newGuid();
        const highlight = {
            "type": "highlight",
            "color": "#0099ff", //blue
            "flags": "readOnly",
            "date": "",
            "name": `${highlightId}`,
            "page": page,
            "rect": `${rectA},${rectB},${rectC},${rectD}`,
            "contents": "",
            "width": 2,
            "customEntries": {},
            "style": "solid",
            "opacity": 1,
            "creationdate": "",
            "subject": "",
            "title": destinationItemText,
            "coords": `${rectA},${rectD},${rectC},${rectD},${rectA},${rectB},${rectC},${rectB}`,
        }
        return highlight;
    }
    static async highlightLinkSheetCurrentPage(pageIndex: number, allLinkSheetsData: LinkSheetData[], collisionRect?: LocationScan) {
        let linkSheetsDataFilter = allLinkSheetsData.filter(val => val.sourceItem.pageIndex === pageIndex);
        if (collisionRect !== undefined) {
            linkSheetsDataFilter = linkSheetsDataFilter.filter(val => !PdfHelper.checkRectsCollision(collisionRect, val.sourceItem.rect));
        }
        let temp = new Promise((_resolve, _reject) => { });
        await PdfHelper.removeAllSingleHighlight().then(async item => {
            const pdfViewer = getPdfViewer();
            // const pdfPage = await pdfViewer.getPDFPageRender(pageIndex)?.getPDFPage();
            // if (pdfViewer) {
            //     linkSheetsDataFilter.forEach(async val => await pdfPage.addAnnot(PdfHelper.getHighlightObject(val.sourceItem.rect, JSON.stringify(val.destinationItem), pageIndex)));
            // }
            const newObjs = linkSheetsDataFilter.map((val) => PdfHelper.getHighlightObject(val.sourceItem.rect, JSON.stringify(val.destinationItem), pageIndex));
            if (pdfViewer) {
                temp = pdfViewer.getCurrentPDFDoc().importAnnotsFromJSON(newObjs);
                return temp;
            }
        });
    }

    public static async zoomTo(pdfViewer: any, ratio: any, $ui: HTMLElement, mouseEvent: any) {
        const bouding = $ui.getBoundingClientRect();
        const curElementWidth = bouding.width;
        const curElementHeight = bouding.height;
        const { left, top } = bouding;
        if (ratio === 'fit') {
            const page = await PdfHelper.getPdfPage(pdfViewer, PdfHelper.currentPage(pdfViewer));
            const width = page.getWidth();
            const height = page.getHeight();
            if (width / curElementWidth > height / curElementHeight) {
                pdfViewer.zoomTo('fitWidth');
            } else {
                pdfViewer.zoomTo('fitHeight');
            }
        } else {
            let x = left + curElementWidth / 2;
            let y = top + curElementHeight / 2;
            if (mouseEvent) {
                x = mouseEvent.clientX;
                y = mouseEvent.clientY;
            }
            PdfHelper.zoomToExecute(pdfViewer, ratio, { x, y });
        }
    }
    public static zoomToExecute(pdfViewer: any, nextScale: any, location: { x: number, y: number }) {
        const { x, y } = location;
        let pageIndex = 0; let posLeft = 0; let posTop = 0;
        const pdfDocRender = pdfViewer.getPDFDocRender();
        const viewRender = pdfDocRender.viewerRender.$ui.parent()[0] as HTMLElement;
        const bounding = viewRender.getBoundingClientRect();
        const { left, top } = bounding;
        let pointLeft = 0;
        let pointTop = 0;
        let visiblePages = pdfDocRender.pagesRender.viewMode.getVisibleIndexes() || [0];
        for (let i = 0; i < visiblePages.length; i++) {
            let visiblePage = visiblePages[i];
            let pageBoudary = pdfDocRender.getPDFPageRender(visiblePage).$ui.get(0).getBoundingClientRect();
            if (x <= pageBoudary.right && x >= pageBoudary.left && y >= pageBoudary.top && y <= pageBoudary.bottom) {
                pageIndex = visiblePage;
                pointLeft = left - x;
                posLeft = x - pageBoudary.left;
                if (posLeft < 0) posLeft = 0;
                pointTop = top - y;
                posTop = y - pageBoudary.top;
                if (posTop < 0) posTop = 0; break;
            }
        }
        let pdfPageRender = pdfDocRender.getPDFPageRender(pageIndex);
        let devicePoint = pdfPageRender.page.reverseDevicePoint([posLeft, posTop], pdfPageRender.scale, pdfPageRender.getRotation());
        let deviceOffset = pdfPageRender.page.reverseDeviceOffset([pointLeft, pointTop], nextScale, pdfPageRender.getRotation());
        const params = {
            pageIndex,
            x: devicePoint[0] + deviceOffset[0],
            y: devicePoint[1] + deviceOffset[1],
        };
        pdfViewer.zoomTo(nextScale, params);
    }
    public static async zoomExcute(pdfViewer: any, ratio: any, mouseEvent?: any) {
        const $pdfUI = pdfViewer.$ui[0].parentElement;
        await PdfHelper.zoomTo(pdfViewer, ratio, $pdfUI, mouseEvent);
    }

    public static checkHighLightCollideLink(currentPage: number, linkRect: BoundaryScan, linkSheetsData: LinkSheetData[]) {
        const sourceLinkRect: LocationScan = {
            width: Math.abs(linkRect.right - linkRect.left),
            height: Math.abs(linkRect.top - linkRect.bottom),
            x: linkRect.left,
            y: linkRect.bottom,
        }
        const highlightCollide = linkSheetsData.find(val => {
            if (val.sourceItem.pageIndex === currentPage) {
                return PdfHelper.checkRectsCollision(val.sourceItem.rect, sourceLinkRect);
            } else {
                return false;
            }
        });

        return highlightCollide;
    }
    public static getAnnotPosByRotation(newAnnot: any) {
        const annotInfo = newAnnot.info;
        const annotPage = newAnnot.page.info;
        console.log(annotInfo);
        console.log(annotPage)
        const pdfHeight = annotPage.mediaHeight;
        const pdfWidth = annotPage.mediaWidth;
        let position;
        switch (annotPage.rotation) {
            case 0:
                return position = { x: annotInfo.rect.left, y: annotInfo.rect.top };
            case 1:
                return position = { x: annotInfo.rect.top, y: annotInfo.rect.left };
            case 2:
                return position = { x: annotInfo.rect.left, y: annotInfo.rect.top }; // not test
            case 3:
                return position = { x: annotInfo.rect.top, y: annotInfo.rect.right }; // not test
            default:
                break;
        }
    }
}