/* eslint-disable @typescript-eslint/no-explicit-any */
import { DetailInfoPage, LocationScan, PageReviewShortcut } from "./define";

class Utils {
    static setLocalStorage(key: string, value: unknown): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
    static getValueLocalStorage(key: string): any | null {
        const value = localStorage.getItem(key);
        let re = null;
        value && (re = Utils.parseJson(value));
        return re;
    }
    static removeItemLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }
    static parseJson(str: string): any | null {
        try {
            return JSON.parse(str);
        } catch (e) {
            return null;
        }
    }

    static getFileNameWithoutExtension(fileOrigin: string | undefined): string {
        return fileOrigin?.substring(0, fileOrigin.lastIndexOf('.')) ?? '';
    }
    static parseUrl(obj: { [key: string]: string }): URLSearchParams {
        const params = new URLSearchParams();
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (key && value) {
                params.set(key, value)
            }
        })
        return params;
    }

    static getFirstItem<T>(list: T[], condition: (item: T) => boolean): { item: T, index: number } | null {
        let re: { item: T, index: number } | null = null;
        list.some((item, index) => {
            if (condition(item)) {
                re = { item, index };
                return true;
            }
            return false;
        });
        return re;
    }
    static isRectangleEnoughLarge(x1: number, y1: number, x2: number, y2: number): boolean {
        return (Math.abs(x2 - x1) >= 2 && Math.abs(y2 - y1) >= 2);
    }
    static getTitle(str: string): string {
        const regex = /(^.)(\w+)([A-Z]\w+)/;
        const match = str.match(regex);
        if (match && match.length > 2) {
            return `${match[1].toUpperCase()}${match[2]} ${match[3]}`;
        }
        const uppercaseFirstChar = str.charAt(0).toUpperCase() + str.slice(1);
        return uppercaseFirstChar;
    }
    static uppercaseFirstChar(str: string): string {
        return str && (str.charAt(0)?.toUpperCase() + str.slice(1));
    }
    static checkMatched(data: any, pageIndex: number): boolean {
        if (data.pageAnalysisItems) {
            return !data.pageAnalysisItems.some((item: any) =>
                item.analysisvalue === null || (item.analysisvalue !== null && item.analysisvalue.priority === 0)
            );
        } else {
            return false;
        }
    }
    static checkMatchByPageDetail(data: any): boolean {
        // const { pageIndex, ...newData } = data;
        // const arrFields = Object.keys(newData);
        // if (arrFields && arrFields.length > 0) {
        //     const noMatched = arrFields.some(f => {
        //         const key = f as ExcludeReviewField;
        //         const field = data[key];
        //         if (!field || field.priority === undefined || field.priority !== 1) {
        //             return true;
        //         }
        //         return false;
        //     });
        //     return !noMatched;
        // }
        return false;
    }
    static download(url: string, fileName: string | undefined) {
        const a = document.createElement("a");
        a.setAttribute("display", "none");
        a.target = '_blank';
        document.body.appendChild(a);
        a.href = url;
        a.click();
        a.download = fileName!;
        window.URL.revokeObjectURL(url);
    }
    public static newGuid() {
        return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0;
            // eslint-disable-next-line no-mixed-operators
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

export default Utils;
