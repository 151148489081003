import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import { combineEpics, Epic } from "redux-observable";
import actionInfoReducer, { completeVerification$, fetchDetailInfoPage$, fetchOverView$ } from "./actionInfo.slice";
import appReducer, { escapeAll$ } from "./app.slice";
import detailPageReducer, { apply$, fetchDisciplineType$, fetchScanField$, fetchSheetType$, fetchTBgraphicInfo$, fetchTBreview$, updateTBDiscipline$, updateTBSheet$ } from "./detailPage.slice";
import listPagesReducer, { analytic$, fetchListPages$ } from "./listPages.slice";
import pdfViewerReducer from "./pdfViewer.slice";

const rootReducer = combineReducers({
    pdfViewer: pdfViewerReducer,
    actionInfo: actionInfoReducer,
    app: appReducer,
    detailPage: detailPageReducer,
    listPages: listPagesReducer
});

export const rootEpic = combineEpics(
    fetchOverView$,
    fetchDetailInfoPage$,
    fetchScanField$,
    apply$,
    escapeAll$,
    completeVerification$,
    fetchListPages$,
    analytic$,
    fetchTBreview$,
    fetchTBgraphicInfo$,
    fetchDisciplineType$,
    fetchSheetType$,
    updateTBDiscipline$,
    updateTBSheet$,
);

export type RootState = ReturnType<typeof rootReducer>;
export type RootEpic = Epic<AnyAction, AnyAction, RootState>;
export default rootReducer;
